import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"/>
      <p className="loading-message">Loading...</p>
    </div>
  );
};

export default Loading;
