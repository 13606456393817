import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./MoviesContainer.css";

import FilmContainer from "../FilmContainer/index.tsx";
import Loading from "../Loading/index.tsx";
import { updateMovieGenres } from "../../util/helpers.ts";
import TotalResults from "../PageResults/TotalResults/index.tsx";
import Pagination from "../PageResults/TotalResults/Pagination/index.tsx";
import Filter from "../Filter/index.tsx";
import { FilterOptions, MovieData } from "../../models/movieData.model.ts";
import { BASE_API_URL } from "../../util/constants.tsx";

const headers: HeadersInit = {
  Authorization: process.env.REACT_APP_MOVIE_KEY || "",
};

const MoviesContainer: React.FC = () => {
  const location = useLocation();
  const [movieData, setMoviesData] = useState<MovieData | undefined>();
  const [moviesDataWithGenere, setMoviesWithGenre] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setPathName] = useState<string>(location.pathname);
  const [filters, setFilters] = useState<FilterOptions>();
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);
  const [hasMoreData] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const pageNavTitle = location.pathname
    .split("/")
    .slice(2)
    .join("")
    .split("_")
    .join(" ");

  const filmType = location.pathname.split("/")[1];

  const checkIsMobileDevice = () => {
    setIsMobileDevice(window.innerWidth < 768);
  };

  useEffect(() => {
    checkIsMobileDevice();
  }, []);

  useEffect(() => {
    if (!isMobileDevice) {
      return;
    }

    const handleScroll = () => {
      if (
        containerRef.current &&
        window.innerHeight + window.scrollY >=
          containerRef.current.scrollHeight - 100
      ) {
        if (hasMoreData && !isLoading) {
          setPageNumber((prev: number) => prev + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileDevice, isLoading, hasMoreData]);

  useEffect(() => {
    if (filters) {
      return;
    }
    const FILM_URL = `${BASE_API_URL}${location.pathname}?api_key=${process.env.REACT_APP_API_MOVIE_KEY}`;

    const fetchData = async () => {
      setIsLoading(true);

      try {
        setPathName((prevPath: any) => {
          if (prevPath !== location.pathname) {
            setPageNumber(1);
            return;
          }
          return prevPath;
        });

        const response = await axios.get(FILM_URL, {
          headers: headers,
          params: {
            include_adult: false,
            language: "en-US",
            region: "US",
            page: pageNumber,
          },
        });

        const data = await response.data;
        setMoviesData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [filters, isMobileDevice, location.pathname, pageNumber]);

  useEffect(() => {
    if (!movieData) {
      return;
    }

    const fetchGenres = async () => {
      const GENRE_URL = `${BASE_API_URL}/genre/${filmType}/list?language=en?api_key=${process.env.REACT_APP_API_MOVIE_KEY}`;

      try {
        setIsLoading(true);
        const response = await axios.get(GENRE_URL, { headers });

        const data = await response.data;
        const { genres } = data;

        const updatedGeneralMovies = updateMovieGenres(
          genres,
          movieData.results
        );
        setMoviesWithGenre(updatedGeneralMovies);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchGenres();
  }, [filmType, movieData]);

  useEffect(() => {
    if (!filters) {
      return;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear() + 1;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDateReleaseDate = `${year}-${month}-${day}`;

    let url = `${BASE_API_URL}/discover/movie?include_adult=false&watch_region=US&language=en-US&page=${pageNumber}&release_date.lte=${formattedDateReleaseDate}`;

    if (filters.sort_by !== null) {
      url += `&sort_by=${encodeURIComponent(filters.sort_by)}`;
    }

    if (filters.genre !== null && filters.genre.length > 0) {
      url += `&with_genres=${encodeURIComponent(filters.genre)}`;
    }

    if (filters.certification !== null && filters.certification.length > 0) {
      url += `&certification=${encodeURIComponent(filters.certification)}`;
    }

    const applyFilter = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(url, { headers });
        const data = await response.data;
        setMoviesData(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    applyFilter();
  }, [filters, pageNumber]);

  const handlePageForward = () => {
    setPageNumber((prev: number) => (prev += 1));
  };
  const handlePageBackward = () => {
    setPageNumber((prev: number) => (prev -= 1));
  };

  const handleFilterMovies = useCallback((filters: FilterOptions) => {
    setFilters(filters);
  }, []);

  if (isLoading || !moviesDataWithGenere || !movieData) {
    return <Loading />;
  }
  return (
    <div className="movies-container container" ref={containerRef}>
      <h3 className="page-title-primary">
        {pageNavTitle} {filmType} {" "}
        <TotalResults totalResults={movieData?.total_results} />
      </h3>
      <div className="filter-container">
        {!isMobileDevice && (
          <Pagination
            paginationData={movieData}
            onHandlePageBackward={handlePageBackward}
            onHandlePageForward={handlePageForward}
            pageNumber={pageNumber}
          />
        )}
        <Filter onFilterChange={handleFilterMovies} />
      </div>

      <FilmContainer filteredMovies={moviesDataWithGenere} />
    </div>
  );
};

export default MoviesContainer;
