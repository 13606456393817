import { genreData } from "../models/movieData.model";

export const updateMovieGenres = (genres: genreData[], movies: any) => {
  const findGenreName = (genreId: number) => {
    const genre = genres.find((genre) => genre.id === genreId);
    return genre ? genre.name : "Unknown";
  };

  return movies.map((movie: any) => {
    const genreNames = movie.genre_ids.map((genreId: any) => findGenreName(genreId));
    return {
      ...movie,
      genreNames: [...genreNames],
    };
  });
};

export const convertDate = (date: string) => {
  if(date === ''){
    return 
  }
  return new Date(date).toLocaleDateString("en-US");
};

export const headers = {
  Authorization: process.env.REACT_APP_MOVIE_KEY || "",
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
};

export const getCurrentDate = () => {

  const currentDate = new Date();
  const year = currentDate.getFullYear() + 1;
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const convertRuntime = (runtime: number) => {
  const hours = Math.floor(runtime / 60);
  const minutes = runtime % 60;
  return `${hours}h ${minutes}min`;
};

export const formatedCurrency = (number: number) => {
  if (number === 0) {
    return "-";
  }

  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};