import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsFacebook,
  BsInstagram,
  BsTiktok,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import Loading from "../Loading";
import "./SocialList.css";

type SocialLinks = {
  [key: string]: string | null;
};

const headers: HeadersInit = {
  Authorization: process.env.REACT_APP_MOVIE_KEY || "",
};

const updateSocialLinks = (socialData: any) => {
  const validKeys = [
    "facebook_id",
    "instagram_id",
    "tiktok_id",
    "twitter_id",
    "youtube_id",
  ];

  const filteredObject = Object.keys(socialData)
    .filter((key) => socialData[key] !== null && validKeys.includes(key))
    .reduce((obj: any, key: string) => {
      obj[key] = socialData[key];
      return obj;
    }, {});

  return filteredObject;
};

const getSocialIcon = (key: any) => {
  switch (key) {
    case "twitter_id":
      return <BsTwitter fill="#199DF0" />;
    case "facebook_id":
      return <BsFacebook fill="#1877F2" />;
    case "tiktok_id":
      return <BsTiktok fill="white" />;
    case "instagram_id":
      return <BsInstagram fill="#833AB4" />;
    case "youtube_id":
      return <BsYoutube fill="red" />;
    default:
      return null;
  }
};

interface SocialListProps {
  url: string;
  showHeader: boolean;
}

const SocialList: React.FC<SocialListProps> = ({ url, showHeader = true }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [socialLinks, setSocialLinks] = useState<any>(null);

  useEffect(() => {
    const getSocialMedia = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(url, { headers });
        const data = await response.data;
        const activeSocialLinks = updateSocialLinks(data);
        setSocialLinks([activeSocialLinks]);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getSocialMedia();
  }, [url]);

  if (isLoading || !socialLinks) {
    return <Loading />;
  }
  return (
    <>
      {showHeader && <h2 className="social-title header">Social</h2>}
      <ul className="social-link-list">
        {socialLinks.length === 1 &&
        Object.keys(socialLinks[0]).length === 0 ? (
          <p>No social information at this time.</p>
        ) : (
          socialLinks.map((obj: SocialLinks, index: number) => (
            <React.Fragment key={index}>
              {Object.entries(obj).map(([key, value]) => {
                const socialMediaName = key.slice(0, key.length - 3);
                return (
                  <li
                    key={key}
                    style={{
                      display: key === "wikidata_id" ? "none" : undefined,
                    }}
                  >
                    <a
                      title={`Visit ${socialMediaName}`}
                      href={`https://www.${socialMediaName}.com/${value}`}
                    >
                      {getSocialIcon(key)}
                    </a>
                  </li>
                );
              })}
            </React.Fragment>
          ))
        )}
      </ul>
    </>
  );
};

export default SocialList;
