import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Loading from "../Loading";
import CastCrewAvatar from "../CastCrewAvatar";
import "./People.css";
import Pagination from "../PageResults/TotalResults/Pagination";
import { headers } from "../../util/helpers";
import { BASE_API_URL } from "../../util/constants";

const People: React.FC = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [person, setPerson] = useState<any>(null);

  const pageNavTitle = location.pathname
    .split("/")
    .slice(2)
    .join("")
    .split("_")
    .join(" ");

  useEffect(() => {
    const getPopularPeople = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(`${BASE_API_URL}/person/popular`, {
          headers,
          params: {
            page: pageNumber,
          },
        });
        const data = await response.data;
        setPerson(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getPopularPeople();
  }, [pageNumber]);

  const handlePageForward = () => {
    setPageNumber((prev: number) => (prev += 1));
  };
  const handlePageBackward = () => {
    setPageNumber((prev: number) => (prev -= 1));
  };

  if (isLoading || !person) {
    return <Loading />;
  }

  return (
    <section className="people-root container">
      <div className="people-filter-container">
        <h3 className="page-title-primary">{pageNavTitle} People</h3>
        <Pagination
          paginationData={person}
          onHandlePageBackward={handlePageBackward}
          onHandlePageForward={handlePageForward}
          pageNumber={pageNumber}
        />
      </div>
      <div id="people-container">
        {person.results.map((p: any) => {
          return <CastCrewAvatar castMem={p} key={crypto.randomUUID()} />;
        })}
      </div>
    </section>
  );
};

export default People;
