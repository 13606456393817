import React, { useState } from "react";
import './TruncatedText.css';

interface TruncatedTextProps {
  text: string;
  maxLength: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength);

  return (
    <p className="truncated-text">
      {displayText}
      {text.length > maxLength && (
        <span className="toggle-text" onClick={toggleReadMore}>
          {isExpanded ? (
            <span className="truncated-text-toggle"> Show Less</span>
          ) : (
            <span className="truncated-text-toggle">
              {" "}
              ...Show More
            </span>
          )}
        </span>
      )}
    </p>
  );
};

export default TruncatedText;
