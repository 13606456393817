import React from "react";
import { useNavigate } from "react-router-dom";
import "./Error.css";

const Error = () => {
  const navigate = useNavigate();
  return (
    <div className="error-container">
      <h1 className="error-message">Something went wrong</h1>
      <button onClick={() => navigate(-1)} className=" button primary">
        Go Back
      </button>
    </div>
  );
};

export default Error;
