import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./Home.css";
import { headers } from "../../util/helpers";
import Loading from "../Loading";
import { HomeMovieData } from "../../models/movieData.model";
import FilmContainer from "../FilmContainer";
import SearchInput from "../SearchInput";
import { TypeAnimation } from "react-type-animation";
import { BASE_API_URL, BASE_IMAGE_URL } from "../../util/constants";

const TIME_FRAMES = {
  DAY: "day",
  WEEK: "week",
};

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trendingTimeframe, setTrendingTimeframe] = useState<string>(
    TIME_FRAMES.DAY
  );
  const [trendingMovies, setTrendingMovies] = useState<
    HomeMovieData | undefined
  >();
  const [officialTrailerList, setOfficialTrailersList] = useState(null);
  const [headerOpacity, setHeaderOpacity] = useState<number>(1);
  const [textContainerZIndex, setTextContainerZIndex] = useState<number>(3);

  useEffect(() => {
    const getTrending = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_API_URL}/trending/movie/${trendingTimeframe}`,
          { headers }
        );
        const data = await response.data;
        setTrendingMovies(data.results);
        setIsLoading(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getTrending();
  }, [trendingTimeframe]);

  useEffect(() => {
    let trailerPrelist: number[] = [];

    if (!trendingMovies) {
      return;
    } else {
      trailerPrelist = trendingMovies && trendingMovies.slice(0, 6);
    }

    const getTrailers = async () => {
      try {
        const promises = trailerPrelist.map((film: any) => {
          return fetchFilms(`${BASE_API_URL}/movie/${film.id}/videos`);
        });

        const responses = await Promise.all(promises);

        const officialTrailers = responses.flatMap((item) =>
          item.results
            .filter((result: any) => result.name === "Official Trailer")
            .map((result: any) =>
              Object.assign({}, { filmId: item.id, ...result })
            )
        );

        setOfficialTrailersList(officialTrailers);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getTrailers();
  }, [trendingMovies]);

  const fetchFilms = async (url: string) => {
    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fadeOutTimeout = setTimeout(() => {
      setHeaderOpacity(0);
      setTextContainerZIndex(0);
    }, 7000);

    return () => {
      clearTimeout(fadeOutTimeout);
    };
  }, []);

  const handleToggleTimeframe = useCallback(() => {
    setTrendingTimeframe(
      trendingTimeframe === TIME_FRAMES.DAY ? TIME_FRAMES.WEEK : TIME_FRAMES.DAY
    );
  }, [trendingTimeframe]);

  if (isLoading || !trendingMovies || !officialTrailerList) {
    return <Loading />;
  }

  return (
    <main className="home-root container">
      <div
        className="home-header-container"
        style={{
          backgroundImage: `url(${BASE_IMAGE_URL}${trendingMovies[0].backdrop_path})`,
        }}
      >
        <div
          className="text-container"
          style={
            {
              "--header-opacity": headerOpacity,
              "--zIndex": textContainerZIndex,
            } as React.CSSProperties
          }
        >
          <h1>Film ST⭐️R DB</h1>
        </div>
        <div className="home-header-content">
          <h1>Welcome!</h1>
          <h3 className="subheader">
            <TypeAnimation
              cursor
              speed={30}
              sequence={[
                "Lights, camera, action! Movie Mania: embark on a captivating cinematic journey, explore and unleash imagination. - By Mike KS",
              ]}
              wrapper="span"
            />
          </h3>
          <SearchInput />
        </div>
      </div>
      <div className="home-container">
        <div>
          <h3 className="home-subtitle header">Trending</h3>
          <div className="button-group-container">
            <button
              className={`button secondary ${
                trendingTimeframe === TIME_FRAMES.DAY ? "secondary" : "tertiary"
              }`}
              onClick={handleToggleTimeframe}
            >
              Today
            </button>
            <button
              className={`button  ${
                trendingTimeframe === TIME_FRAMES.WEEK
                  ? "secondary"
                  : "tertiary"
              }`}
              onClick={handleToggleTimeframe}
            >
              This Week
            </button>
          </div>
        </div>
        <FilmContainer
          filteredMovies={trendingMovies}
          customStyles={{ display: "flex", overflowY: "scroll" }}
        />
      </div>
      <div className="home-subtitle-header-container">
        <h3 className="home-subtitle header">Latest Trailers</h3>
      </div>
      <div
        className="home-background-container"
        style={{
          backgroundImage: `url(${BASE_IMAGE_URL}${trendingMovies[1].backdrop_path})`,
        }}
      >
        <ul className="home-background">
          {officialTrailerList &&
            trendingMovies &&
            officialTrailerList?.map((trailer: any) => {
              let thumbNailInfo = trendingMovies.find((movie: any) => {
                return trailer.filmId === movie.id;
              });
              if (!thumbNailInfo) {
                thumbNailInfo = trendingMovies[0];
              }
              return (
                <li key={trailer.id}>
                  <iframe
                    className="home-trailer-video"
                    title="YouTube Video"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    src={`https://www.youtube.com/embed/${trailer.key}`}
                    allowFullScreen={true}
                    allow="autoplay; encrypted-media"
                    srcDoc={`<style>
                    * {
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                    }
                    
                    body, html {
                      height: 100%;
                    }
                    
                    img, svg {
                      position: absolute;
                      width: 100%;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                    }

                    body h5 {
                      color: #ededed;
                      position: absolute;
                      background: rgba(0,0,0,0.7);
                      width: 100%;
                      padding: 3px;
                      font-family: sans-serif;
                    }
                    
                    svg {
                      filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                      transition: all 250ms ease-in-out;
                    }
                    
                    body:hover svg {
                      filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                      transform: scale(1.2);
                    }
                    
                    </style>
                    <a href="https://www.youtube.com/embed/${trailer.key}?autoplay=1">
                      <img src="${BASE_IMAGE_URL}${thumbNailInfo.backdrop_path}" alt='Official Trailer'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather
                       feather-play-circle'><circle cx='12' cy='12' r='10'>
                      </circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                    </a>
                    <h5>${thumbNailInfo.title}<h4/>
                    `}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </main>
  );
};

export default Home;
