import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MoviesContainer from "../MoviesContainer";
import Person from "../People/Person/index.tsx";
import ErrorPage from "../Error/index.tsx";
import MovieDetailsPage from "../MovieDetails/index.tsx";
import NavBar from "../NavBar/index.tsx";
import People from "../People/index.tsx";
import Home from "../Home/index.tsx";
import SearchResults from "../SearchResults/index.tsx";

const Footer = lazy(() => import("../Footer/index.tsx"));

const movieRoutes = [
  "/movie/popular",
  "movie/upcoming",
  "movie/top_rated",
  "movie/now_playing",
];

const tvRoutes = ["/tv/popular"];

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {movieRoutes.map((path) => (
          <Route path={path} element={<MoviesContainer />} key={path} />
        ))}
        {false && tvRoutes.map((path) => (
          <Route path={path} element={<MoviesContainer />} key={path} />
        ))}
        <Route path="/search-results/:query" element={<SearchResults />} />
        <Route path="/person/:personId" element={<Person />} />
        <Route path="/people/popular/" element={<People />} />
        <Route path="/movie/:movieId" element={<MovieDetailsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
