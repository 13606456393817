import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchInput.css";


const SearchInput: React.FC = ( ) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleSearch = useCallback(async () => {
    if (searchQuery.trim() !== "") {

      navigate(`/search-results/${encodeURIComponent(searchQuery)}`);
    }
  }, [navigate, searchQuery]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  return (
    <div className="search-wrapper">
      <input
        className="search-input"
        type="text"
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search Movies..."
        onKeyDown={handleKeyPress}
      />
      <button className="button tertiary" onClick={handleSearch}>Search</button>
    </div>
  );
};

export default SearchInput;
