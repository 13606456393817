import React from "react";
import "./CastCrewAvatar.css";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import NoResultBackup from "../People/NoResultBackup";
import { truncateText } from "../../util/helpers";

interface CastCrewAvatarProps {
  castMem: {
    profile_path: string;
    name: string;
    character: string;
    id: number;
  };
}

const CastCrewAvatar: React.FC<CastCrewAvatarProps> = ({ castMem }) => {
  const BASE_PROFILE_URL: string = "https://image.tmdb.org/t/p/w185";
  const MAX_NAME_LENGTH: number = 16;
  const MAX_CHARACTER_LENGTH: number = 15;

  if (!castMem) {
    return <Loading />;
  }

  return (
    <article className="cast-crew-item" key={castMem.id}>
      <Link to={`/person/${castMem.id}`}>
        {castMem.profile_path ? (
          <img src={`${BASE_PROFILE_URL}${castMem.profile_path}`} alt={castMem.name} />
        ) : (
          <NoResultBackup isUser={true} width="150px" height="222px" />
        )}
        <h3 className="cast-crew-name">{truncateText(castMem.name, MAX_NAME_LENGTH)}</h3>
        {castMem.character && (
          <p>{truncateText(castMem.character, MAX_CHARACTER_LENGTH)}</p>
        )}
      </Link>
    </article>
  );
};

export default CastCrewAvatar;
