import React, { useCallback } from "react";
import Select, { ValueType } from "react-select";

type ParamFunc = (value: string) => void;
type DropdownOptions = {
  value: string;
  label: string;
};

interface DropdownProps {
  onUpdateSort: ParamFunc;
  options: DropdownOptions[];
  placeholder: string;
  customStyles?: Record<string, any>;
}

const Dropdown: React.FC<DropdownProps> = ({
  onUpdateSort,
  options,
  placeholder,
  customStyles = {},
}) => {
  const handleOptionChange = useCallback(
    (sortBy: ValueType<DropdownOptions, false>) => {
      if (sortBy) {
        onUpdateSort(sortBy.value);
      }
    },
    [onUpdateSort]
  );

  return (
    <div>
      <Select
        options={options}
        styles={{
          control: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: customStyles.backgroundColor || "#1a1a1a",
            color: customStyles.color || "#FFFFFF",
          }),
          placeholder: (baseStyles: any) => ({
            ...baseStyles,
            color: customStyles.color || "#FFFFFF",
          }),
          menu: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: customStyles.menuBackgroundColor || "#FFFFFF",
            color: customStyles.menuColor || "#000000",
            borderRadius: customStyles.menuBorderRadius || "4px",
            zIndex: customStyles.menuZIndex || 5,
          }),
          singleValue: (baseStyle: any) => ({
            ...baseStyle,
            color: customStyles.color || "#FFFFFF",
          }),
          ...customStyles,
        }}
        placeholder={placeholder}
        onChange={handleOptionChange}
      />
    </div>
  );
};

export default Dropdown;
