import axios from "axios";
import React, { useEffect, useState } from "react";
import { headers } from "../../util/helpers.ts";
import "./MovieAssets.css";
import { BASE_API_URL, BASE_IMAGE_URL } from "../../util/constants.tsx";

interface MovieAssetsProps {
  movieId: string | undefined;
}

const MovieAssets: React.FC<MovieAssetsProps> = ({ movieId }) => {
  const [movieImageData, setMovieImageData] = useState<any>(null);
  const [movieAssetHeaders, setHeaders] = useState<string[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [selectedMedia, setSelectedMediaType] = useState<any>(null);

  useEffect(() => {
    const fetchMovieImages = async () => {
      try {
        const URL = `${BASE_API_URL}/movie/${movieId}/images`;
        const response = await axios.get(URL, { headers });
        const data = response.data;

        setMovieImageData(data);

        const movieHeaders = Object.keys(data).filter(
          (key) => key !== "id" && key !== "logos"
        );
        setHeaders(movieHeaders);
        setSelected(movieHeaders[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMovieImages();
  }, [movieId]);

  useEffect(() => {
    if (selected === null) {
      return;
    }
    const mediaTypeInfo = movieImageData[selected];
    setSelectedMediaType(mediaTypeInfo);
  }, [movieImageData, selected]);

  const onSelect = (header: string) => {
    setSelected(header);
  };

  return (
    <>
      <ul className="header-root">
        {movieAssetHeaders.map((header) => {
          return (
            <li
              key={crypto.randomUUID()}
              className={`header-title ${
                selected === header ? "selected" : ""
              }`}
              onClick={() => onSelect(header)}
            >
              {header}
              <span>{movieImageData[header].length}</span>
            </li>
          );
        })}
      </ul>

      {selected && (
        <ul className="selected-media-container">
          {selectedMedia &&
            selectedMedia.map((media: any) => {
              return (
                <li key={crypto.randomUUID()} className={`${selected}`}>
                  <img
                    loading="lazy"
                    src={`${BASE_IMAGE_URL}${media.file_path}`}
                    alt={selected}
                  />
                </li>
              );
            })}
        </ul>
      )}
    </>
  );
};

export default MovieAssets;
