import React from "react";
import { BsImage } from "react-icons/bs";
import "./NoFilmCover.css";

type imageContainer = {
  width: string;
  height: string;
}
interface NoFilmCovers {
  fontSize: number | undefined;
  children: React.ReactNode | undefined;
  width: string | undefined;
  height: string | undefined;
  containerStyle: imageContainer | undefined;
}

const NoFilmCover: React.FC<NoFilmCovers> = ({ fontSize, children, width, height, containerStyle }) => {
  return (
    <div className="movie-image-container">
      <div className="no-image-container" style={{...containerStyle}}>
        <BsImage
          style={{ fontSize, fill: "#B5B5B5", width, height }}
          className="no-film-icon"
        />
      </div>
      {children}
    </div>
  );
};

export default NoFilmCover;
