import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { headers } from "../../util/helpers";
import { Page } from "../../models/movieData.model";
import Loading from "../Loading";
import SearchInput from "../SearchInput";
import "./SearchResults.css";
import Pagination from "../PageResults/TotalResults/Pagination";
import NoFilmCover from "../NoFilmCover";
import StarRating from "../StarRating";
import { BASE_API_URL } from "../../util/constants";

const BASE_PROFILE_URL: string = "https://image.tmdb.org/t/p/w185";

function SearchResults() {
  const { query } = useParams();
  const [resultsData, setSearchResults] = useState<Page | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const getSearchQuery = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_API_URL}/search/movie?query=${query}&include_adult=false&language=en-US&page=${pageNumber}`,
          { headers }
        );
        const data = await response.data;
        setSearchResults(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getSearchQuery();
  }, [pageNumber, query]);

  const handlePageForward = () => {
    setPageNumber((prev: number) => (prev += 1));
  };
  const handlePageBackward = () => {
    setPageNumber((prev: number) => (prev -= 1));
  };

  const convertToReleaseYear = (date: string) => {
    const year = date.split("-")[0];
    return year;
  };

  if (!resultsData || isLoading) {
    return <Loading />;
  }

  return (
    <div className="search-result-root">
      <SearchInput />
      <div>
        <h2>Search Results for: {query}</h2>
        <h4>{resultsData.total_results} Results</h4>
      </div>
      {resultsData.total_pages > 1 && (
        <Pagination
          paginationData={resultsData}
          onHandlePageBackward={handlePageBackward}
          onHandlePageForward={handlePageForward}
          pageNumber={pageNumber}
        />
      )}
      <section className="search-item-section">
        {resultsData.results.map((movie, index) => {
          return (
            <Link to={`/movie/${movie.id}`} className="link" key={index}>
              <div className="search-item-container">
                {movie.poster_path ? (
                  <img
                    src={BASE_PROFILE_URL + movie.poster_path}
                    alt={movie.title}
                    height={150}
                  />
                ) : (
                  <NoFilmCover
                    fontSize={45}
                    containerStyle={{ height: "150px", width: "100px" }}
                    children={undefined}
                    width={undefined}
                    height={undefined}
                  />
                )}
                <div className="search-item-text-content">
                  <h3>{movie.title}</h3>
                  <StarRating voteRating={movie.vote_average} />
                  <p>{convertToReleaseYear(movie.release_date)}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </section>
    </div>
  );
}

export default SearchResults;
