import React from "react";
import { Link } from "react-router-dom";
import "./Poster.css";
import NoFilmCover from "../NoFilmCover";

interface PosterProps {
  movieTitle: string;
  imageSrc: string | undefined;
  id: number;
  releaseDate: string;
  reviewRating: number;
}

const BASE_POSTER_IMAGE_URL: string = "https://image.tmdb.org/t/p/w500";

const Poster: React.FC<PosterProps> = ({
  movieTitle,
  imageSrc,
  id,
  reviewRating,
}) => {
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  };

  function createPercentageCircleSVG(rating: number) {
    if (!rating) {
      return;
    }

    const radius = 18;
    const circumference = 2 * Math.PI * radius;
    const dashArray = (rating / 10) * circumference;
    const percentage = Math.round(rating * 10);

    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="0" width="40" height="40" fill="none" />
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="#1a1a1a"
          strokeWidth="4"
          strokeDasharray={`${circumference}`}
          transform="rotate(-90 20 20)"
          strokeLinecap="round"
        />
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="gold"
          strokeWidth="4"
          strokeDasharray={`${dashArray} ${circumference}`}
          transform="rotate(-90 20 20)"
          strokeLinecap="round"
        />
        <circle cx="20" cy="20" r={radius - 2} fill="#1a1a1a" />
        <foreignObject x="0" y="0" width="40" height="40">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            className="svg-container"
          >
            <div
              className="svg-text"
            >
              {percentage}
              <span className="svg-text-small">%</span>
            </div>
          </div>
        </foreignObject>
      </svg>
    );
  }

  return (
    <div className="film-container">
      <Link to={`/movie/${id}`} className="poster-link">
        {imageSrc ? (
          <div className="movie-image-container">
            <div className="movie-image-review-container">
              <img
                src={BASE_POSTER_IMAGE_URL + imageSrc}
                alt={movieTitle}
                height={250}
              />
              {createPercentageCircleSVG(reviewRating)}
            </div>
            <h5 className="poster-item-detail">
              {truncateText(movieTitle, 18)}
            </h5>
          </div>
        ) : (
          <NoFilmCover fontSize={45} containerStyle={{ height: "250px" }}>
            <h4 className="poster-item-detail">{movieTitle}</h4>
          </NoFilmCover>
        )}
      </Link>
    </div>
  );
};
export default Poster;
