import React from "react";
import "./StarRating.css";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

interface StarRatingProps {
  voteRating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ voteRating }) => {
  const maxRating = 10;
  const normalizedRating = voteRating / maxRating;
  const starRating = normalizedRating * 5;

  const totalStars = 5;
  const fullStars = Math.floor(starRating);
  const decimal = starRating - fullStars;
  const hasHalfStar = decimal >= 0.25 && decimal < 0.75;
  const hasFullStar = decimal >= 0.75;
  const remainingStars =
    totalStars - fullStars - (hasHalfStar ? 1 : 0) - (hasFullStar ? 1 : 0);

  if (voteRating === 0) {
    return (
      <div className="star-container">
        {Array.from({ length: totalStars }, (_, index) => (
          <FaRegStar key={index} className="star" />
        ))}
      </div>
    );
  }

  return (
    <div className="star-container">
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar key={index} className="star" />
      ))}
      {hasHalfStar && <FaStarHalfAlt className="star" />}
      {hasFullStar && <FaStar className="star" />}
      {Array.from({ length: remainingStars }, (_, index) => (
        <FaRegStar key={index} className="star" />
      ))}
    </div>
  );
};

export default StarRating;
