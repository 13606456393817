import { useState, useCallback } from 'react';

const useHover = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const handleMouseEnter = useCallback((item: string) => {
    setHoveredItem(item);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredItem(null);
  }, []);

  return {
    hoveredItem,
    handleMouseEnter,
    handleMouseLeave
  };
};

export default useHover;
