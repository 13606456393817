import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "../Dropdown";
import {
  Certification,
  CertsData,
  FilterOptions,
  Genre,
  GenresData,
} from "../../models/movieData.model";
import { CgClose } from "react-icons/cg";
import { headers } from "../../util/helpers";
import Loading from "../Loading";
import "./Filter.css";
import Chip from "../Chip";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { BASE_API_URL } from "../../util/constants";

type ParamFunc = (filter: FilterOptions) => void;

interface FilterProps {
  onFilterChange: ParamFunc;
}

const filmType = window.location.pathname.split('/')[1]

const API_URLS = {
  CERTIFICATIONS: `${BASE_API_URL}/certification/${filmType}/list`,
  GENRES: `${BASE_API_URL}/genre/${filmType}/list`,
};

const options = [
  {
    label: "Popularity",
    value: "popularity.desc",
  },
  { label: "Popularity Ascending", value: "popularity.asc" },
  { label: "Release Date", value: "release_date.desc" },
  { label: "Rating", value: "vote_average.desc" },
  { label: "Revenue", value: "revenue.desc" },
  { label: "Primary Release Date", value: "primary_release_date.desc" },
  { label: "Title (A-Z)", value: "original_title.asc" },
  { label: "Title (Z-A)", value: "original_title.desc" },
  { label: "Vote Count", value: "vote_count.desc" },
];

const Filter: React.FC<FilterProps> = ({ onFilterChange }) => {
  const [filters, setFilters] = useState<FilterOptions>({
    sort_by: null,
    genre: null,
    certification: null,
  });

  const [certs, setCerts] = useState<CertsData | null>(null);
  const [genres, setGenres] = useState<GenresData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  useEffect(() => {
    const getCertAndGenres = async () => {
      try {
        const [certResponse, genresResponse] = await Promise.all([
          axios.get(API_URLS.CERTIFICATIONS, { headers }),
          axios.get(API_URLS.GENRES, { headers }),
        ]);
        const certData = certResponse.data;

        setGenres(genresResponse.data);
        setCerts(certData.certifications.US);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getCertAndGenres();
  }, []);

  const handleSelectedOption = useCallback((sortOption: string) => {
    setFilters((prevState: FilterOptions) => {
      return { ...prevState, sort_by: sortOption };
    });
  }, []);

  const handleChipChange = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const buttonElement = event.target as HTMLButtonElement;
      const catValue = buttonElement.dataset.categoryvalue;
      const name = buttonElement.name;

      setFilters((prevState: any) => {
        const updatedArray: string[] = prevState[name]
          ? [...(prevState[name] as string[])]
          : [];

        const catIndex =
          catValue !== undefined ? updatedArray.indexOf(catValue) : -1;

        if (catIndex !== -1) {
          return {
            ...prevState,
            [name]: updatedArray.filter((item) => item !== catValue),
          };
        } else {
          return {
            ...prevState,
            [name]: [...updatedArray, catValue],
          };
        }
      });
    },
    []
  );

  const handleApplyFilter = useCallback(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const handleShowFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setShowFilter(false);
  }, []);

  if (isLoading || !genres || !certs) {
    return <Loading />;
  }

  return (
    <div className="filter-root">
      <div onClick={handleShowFilter}>
        <button className="button primary button-icon">
          <BsFillFilterSquareFill />
          Filters
        </button>
      </div>
      {showFilter && (
        <div className="filter-items-container">
          <div className="filter-header-container">
            <h3>Filters</h3>
            <CgClose
              className="filter-close-icon primary"
              onClick={handleCloseFilter}
            />
          </div>

          <Dropdown
            options={options}
            placeholder="Select an option"
            onUpdateSort={handleSelectedOption}
          />
          <section className="filter-section-container">
            <h3>Rating</h3>
            <div className="filter-menu">
              {certs.map((cert: Certification, index: number) => {
                if (filters.certification && filters.certification.length > 0) {
                  return (
                    <Chip
                      name={cert.certification}
                      chipCategory="certification"
                      onChipSelection={handleChipChange}
                      key={index}
                      isSelected={filters.certification.includes(
                        cert.certification
                      )}
                      label={cert.certification}
                    />
                  );
                } else {
                  return (
                    <Chip
                      name={cert.certification}
                      chipCategory="certification"
                      onChipSelection={handleChipChange}
                      key={crypto.randomUUID()}
                      isSelected={false}
                      label={cert.certification}
                    />
                  );
                }
              })}
            </div>
          </section>
          <section className="filter-section-container">
            <h3>Genre</h3>
            <div className="filter-menu">
              {genres.genres.map((genre: Genre) => {
                if (filters.genre && filters.genre.length > 0) {
                  return (
                    <Chip
                      key={genre.id}
                      name={genre.id}
                      chipCategory="genre"
                      onChipSelection={handleChipChange}
                      label={genre.name}
                      isSelected={filters.genre.includes(genre.id.toString())}
                    />
                  );
                } else {
                  return (
                    <Chip
                      key={genre.id}
                      name={genre.id}
                      chipCategory="genre"
                      onChipSelection={handleChipChange}
                      label={genre.name}
                      isSelected={false}
                    />
                  );
                }
              })}
            </div>
          </section>
          <button className="button primary" onClick={handleApplyFilter}>
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default Filter;
