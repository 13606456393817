import React from "react";
import Poster from "../Poster";
import "./FilmContainer.css";
import { HomeMovieData } from "../../models/movieData.model";

interface FilmContainerProps {
  filteredMovies: HomeMovieData;
  customStyles?: React.CSSProperties;
}

const FilmContainer: React.FC<FilmContainerProps> = ({
  filteredMovies,
  customStyles,
}) => {
  if (filteredMovies.length === 0) {
    return;
  }

  return (
    <div className="film-container" style={customStyles}>
      {filteredMovies.map((movie) => (
        <Poster
          reviewRating={movie.vote_average}
          key={movie.id}
          movieTitle={movie.title}
          releaseDate={movie.release_date}
          imageSrc={movie.poster_path}
          id={movie.id}
        />
      ))}
    </div>
  );
};

export default FilmContainer;
