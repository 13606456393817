import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import "./MovieDetails.css";
import axios from "axios";

import Loading from "../Loading/index.tsx";
import StarRating from "../StarRating/index.tsx";
import {
  convertDate,
  convertRuntime,
  formatedCurrency,
} from "../../util/helpers.ts";

import CastCrewAvatar from "../CastCrewAvatar/index.tsx";
import SocialList from "../SocialList/index.tsx";
import TruncatedText from "../TruncatedText/index.tsx";
import Error from "../Error/index.tsx";
import NoResultBackup from "../People/NoResultBackup/index.tsx";
import { headers } from "../../util/helpers.ts";
import FilmContainer from "../FilmContainer/index.tsx";
import { MovieData } from "../../models/movieData.model.ts";
import JustWatch from "../JustWatch/index.tsx";
import { BiLinkExternal } from "react-icons/bi";
import MovieAssets from "../MovieAssets/index.tsx";
import { BASE_API_URL, BASE_IMAGE_URL, BASE_LOGO_URL } from "../../util/constants.tsx";

const basePosterImageURL = "https://image.tmdb.org/t/p/w342";

type LanguageType = {
  iso_639_1: string;
  english_name: string;
  name: string;
};

const leadCrew = ["Director", "Screenplay", "Writer"];

const MovieDetails: React.FC = () => {
  const match = useMatch("/movie/:movieId");
  const { movieId } = match?.params || {};
  const [movie, setSingleMovie] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [movieCast, setMovieCast] = useState<any>(null);
  const [watchFilmInfo, setFilmWatchInfo] = useState<any>(null);
  const [filmCrew, setCrewData] = useState<any>(null);
  const [reviews, setReviews] = useState<any>(null);
  const [movieTrailer, setMovieTrailer] = useState<any>(null);
  const [certification, setCertification] = useState<any>(null);

  const [languages, setLanguages] = useState<LanguageType[]>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [recommendedMovies, setRecommendedMovies] = useState<
    MovieData | undefined
  >();
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  useEffect(() => {
    const fetchMovieDetails = async () => {
      setIsLoading(true);
      try {
        const URL = `${BASE_API_URL}/movie/${movieId}?api_key=${process.env.REACT_APP_API_MOVIE_KEY}`;
        const response = await axios.get(URL, { headers });
        const data = await response.data;
        setSingleMovie(data);
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        console.error(error);
        setIsLoading(false);
      }
    };

    if (movieId) {
      fetchMovieDetails();
    }
  }, [movieId]);

  useEffect(() => {
    const MOVIE_CREDITS_URL = `${BASE_API_URL}/movie/${movieId}/credits?language=en-US`;
    const getMovieCredits = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(MOVIE_CREDITS_URL, { headers });
        const data = await response.data;

        setMovieCast(data);
        setCrewData(() => {
          return data.crew.filter((crew: any) => leadCrew.includes(crew.job));
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getMovieCredits();
  }, [movieId]);

  useEffect(() => {
    const WATCH_METHOD_URL = `${BASE_API_URL}/movie/${movieId}/watch/providers`;
    const getWatchMethod = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(WATCH_METHOD_URL, { headers });
        const data = await response.data;
        setFilmWatchInfo(data.results);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getWatchMethod();
  }, [movieId]);

  useEffect(() => {
    const getReviews = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_API_URL}/movie/${movieId}/reviews?language=en-US&page=1`,
          { headers }
        );
        const data = await response.data;
        setReviews(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getReviews();
  }, [movieId]);

  useEffect(() => {
    const getCertification = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_API_URL}/movie/${movieId}/release_dates`,
          { headers }
        );
        const data = await response.data;
        const cert =
          data.results?.find((cert: any) => cert.iso_3166_1 === "US")
            .release_dates[0].certification || "NR";
        setCertification(cert);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getCertification();
  }, [movieId]);

  useEffect(() => {
    const getLanguages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_API_URL}/configuration/languages`,
          { headers }
        );
        const data = await response.data;
        setLanguages(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };
    getLanguages();
  }, []);

  useEffect(() => {
    const getRecommendations = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_API_URL}/movie/${movieId}/recommendations`,
          { headers }
        );

        const data = await response.data;
        setRecommendedMovies(data.results);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };
    getRecommendations();
  }, [movieId]);

  useEffect(() => {
    const getVideo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_API_URL}/movie/${movieId}/videos`,
          { headers }
        );
        const data = await response.data;
        const foundTrailer = data.results.find((trailer: any) => {
          if (trailer.official && trailer.name === "Official Trailer") {
            return trailer;
          } else {
            return false;
          }
        });
        setMovieTrailer(foundTrailer);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    getVideo();
  }, [movieId]);

  useEffect(() => {
    checkIsMobileDevice();
  }, []);

  const checkIsMobileDevice = () => {
    setIsMobileDevice(window.innerWidth < 768);
  };

  if (hasError) {
    return <Error />;
  }

  if (
    isLoading ||
    !movie ||
    !movieCast ||
    !watchFilmInfo ||
    !reviews ||
    !languages ||
    !recommendedMovies ||
    !movieTrailer === null
  ) {
    return <Loading />;
  }

  const renderWatchText = () => {
    const defaultLanguage = navigator.language.slice(3);
    if (
      Object.keys(watchFilmInfo).length === 0 ||
      !watchFilmInfo[defaultLanguage]
    ) {
      return;
    }
  };

  const renderAvatar = (path: string) => {
    if (path === null) {
      return null
    }

    if (path.includes("http")) {
      return <img src={path.substring(1)} alt="Avatar" className="avatar" />;
    }

    if (!path.includes("http")) {
      return <img src={BASE_LOGO_URL + path} alt="Avatar" className="avatar" />;
    }
  };

  const getOriginalLanguage = () => {
    if (movie.spoken_languages.length === 0) {
      const foundLanguage = languages.find(
        (language) => language.iso_639_1 === movie.original_language
      );
      if (foundLanguage) {
        return foundLanguage.english_name;
      }
    }
  };
  return (
    <div className="movie-details-root">
      <section
        className="movie-details-container"
        style={{
          backgroundImage: `url(${BASE_IMAGE_URL}${movie.backdrop_path})`,
        }}
      >
        <div className="movie-details-container-overlay">
          <div className="movie-details-container-overlay-content-container">
            <h1>{movie.title}</h1>
            <div className="movie-details-wrapper">
              {certification && <p className="movie-item">{certification}</p>}
              <div className="rating-wrapper">
                <StarRating voteRating={movie.vote_average} />
                <p>({movie.vote_count})</p>
              </div>
              <p className="release-year">{convertDate(movie.release_date)}</p>
              <p>{movie.original_language.toUpperCase()}</p>
              <p>{convertRuntime(movie.runtime)}</p>
              {movie.genres.map((gen: any) => (
                <p key={gen.id} className="movie-item">
                  {gen.name}
                </p>
              ))}
            </div>
          </div>
          {movie.poster_path && !movieTrailer && (
            <img
              src={basePosterImageURL + movie.poster_path}
              alt={movie.title}
              className="movie-details-poster-image"
            />
          )}
          {movie.poster_path && movieTrailer && !isMobileDevice && (
            <div className="movie-detail-video-container-desktop">
              <img
                src={basePosterImageURL + movie.poster_path}
                alt={movie.title}
                className="movie-details-poster-image-trailer"
              />

              <iframe
                title="YouTube Video"
                width="100%"
                src={`https://www.youtube.com/embed/${movieTrailer.key}?autoplay=1&mute=1`}
                allowFullScreen={true}
              />
            </div>
          )}
          {movie.poster_path && movieTrailer && isMobileDevice && (
            <iframe
              title="YouTube Video"
              width="100%"
              src={`https://www.youtube.com/embed/${movieTrailer.key}?autoplay=1&mute=1`}
              allowFullScreen={true}
            />
          )}
          {!movie.poster_path && !movieTrailer && (
            <NoResultBackup isUser={false} width={"300px"} />
          )}

          <div className="movie-text-info-container">
            <h3 className="overview-title">Tagline</h3>
            <p className="movie-tagline">{movie.tagline}</p>
            <h3 className="overview-title">Overview</h3>
            {movie.overview ? (
              <p className="overview-text">{movie.overview}</p>
            ) : (
              <p className="overview-text">No overview at this time</p>
            )}
            <section className="">
              <div className="crew-container">
                {filmCrew.map((crew: any) => {
                  return (
                    <div className="crew-wrapper" key={crypto.randomUUID()}>
                      <p>{crew.job}</p>
                      <p className="crew-name">{crew.name}</p>
                    </div>
                  );
                })}
              </div>
            </section>
            {watchFilmInfo && (
              <section className="stream-section">
                <h4 className="watch-options-header">How To Watch</h4>
                {renderWatchText()}
                <JustWatch
                  watchServices={watchFilmInfo}
                  filmTitle={movie.title}
                />
              </section>
            )}
          </div>
        </div>
      </section>
      <div className="social-media-container movie-details-section">
        <SocialList url={`${BASE_API_URL}/movie/${movieId}/external_ids`} />
      </div>
      <section className="movie-status-container movie-details-section">
        <h2 className="movie-details-title header">Box Office</h2>
        <ul className="movie-status-wrapper">
          <li>
            <p>Status</p>
            <p>{movie.status}</p>
          </li>
          <li>
            <p>Budget</p>
            <p>{formatedCurrency(movie.budget)}</p>
          </li>
          <li>
            <p>Revenue</p>
            <p>{formatedCurrency(movie.revenue)}</p>
          </li>
          <li>
            <p>Original Language</p>
            {movie.spoken_languages[0]?.name ? (
              <p>{movie.spoken_languages[0].name}</p>
            ) : (
              <p>{getOriginalLanguage()}</p>
            )}
          </li>
          <li>
            <a href={movie.homepage} className="homepage-link">
              <p>Homepage</p>
              <BiLinkExternal className="homepage-icon" />
            </a>
          </li>
        </ul>
      </section>
      <section className="movie-cast-crew-numbers-container movie-details-section">
        <div className="cast-crew-wrapper">
          <h2 className="movie-details-title header">Cast</h2>
          <div className="cast-crew-container">
            {movieCast.cast.length > 0 ? (
              movieCast.cast.map((castMem: any) => (
                <CastCrewAvatar castMem={castMem} key={castMem.id} />
              ))
            ) : (
              <p className="no-cast-text">
                No cast or crew information available at the moment.
              </p>
            )}
          </div>
        </div>
      </section>
      <section className="review-container movie-details-section">
        <h2 className="movie-details-title header">Featured Reviews</h2>
        {reviews.results.length > 0 ? (
          <div className="review-parent-container">
            {reviews.results
              .map((review: any) => {
                return (
                  <article
                    className="review-card-container-item"
                    key={review.id}
                  >
                    <div className="review-header">
                      {renderAvatar(review.author_details.avatar_path)}
                      <div className="review-header-meta">
                        <h3>
                          {review.author} {convertDate(review.created_at)}
                        </h3>
                        <StarRating voteRating={review.author_details.rating} />
                      </div>
                    </div>
                    <div className="review-content">
                      <TruncatedText text={review.content} maxLength={200} />
                    </div>
                  </article>
                );
              })
              .slice(0, 4)}
          </div>
        ) : (
          <p>No reveiews at this time for {movie.title}</p>
        )}
      </section>
      <section className="movie-details-media-root movie-details-section">
        <h2 className="movie-details-title header">Media</h2>
        <MovieAssets movieId={movieId} />
      </section>
      <section className="review-container movie-details-section">
        <h2 className="movie-details-title header">Recommendations</h2>
        {recommendedMovies.length > 0 ? (
          <FilmContainer
            filteredMovies={recommendedMovies}
            customStyles={{ display: "flex", overflowY: "scroll" }}
          />
        ) : (
          <p>No recommendations at this time</p>
        )}
      </section>
    </div>
  );
};

export default MovieDetails;
