import React from "react";
import { ImUser } from "react-icons/im";
import { BsImage } from "react-icons/bs";
import "./NoResultBackup.css";

interface NoResultBackupProps {
  isUser: boolean;
  width?: number | string;
  height?: number | string;
}

const NoResultBackup: React.FC<NoResultBackupProps> = ({ isUser, width, height }) => {
  const containerStyle = { width, height };

  return (
    <div className="no-user-container" style={containerStyle}>
      {isUser ? <ImUser className="no-profile-img" /> : <BsImage className="no-profile-img" />}
    </div>
  );
};

export default NoResultBackup;
