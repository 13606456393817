import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./JustWatch.css";
import Loading from "../Loading";
import { headers } from "../../util/helpers";
import ReactCountryFlag from "react-country-flag";
import { BASE_API_URL, BASE_LOGO_URL } from "../../util/constants";

const createCountryOptions = (data: any) => {
  return data.reduce((acc: any, curVal: any) => {
    acc.push({
      label: (
        <div className="country-flag-container" key={curVal.iso_3166_1}>
          <ReactCountryFlag
            countryCode={curVal.iso_3166_1}
            style={{
              fontSize: "1.5em",
            }}
          />
          <p>{curVal.native_name}</p>
        </div>
      ),
      value: curVal.iso_3166_1,
      countryCode: curVal.iso_3166_1,
    });
    return acc;
  }, []);
};

interface JustWatchProps {
  watchServices: any;
  filmTitle: string;

}

const JustWatch: React.FC<JustWatchProps> = ({
  watchServices,
  filmTitle,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countryOptions, setCountryOptions] = useState<any>();
  const [countryCode, setCountryCode] = useState(navigator.language.slice(3));

  useEffect(() => {
    const getCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_API_URL}/configuration/countries`,
          { headers }
        );
        const data = await response.data;
        setCountryOptions(createCountryOptions(data));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getCountries();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const getDefaultValue = () => {
    return (
      countryOptions &&
      countryOptions.find((option: any) => option.countryCode === countryCode)
    );
  };

  const handleOptionChange = ({ countryCode }: { countryCode: string }) => {
    setCountryCode(countryCode);
  };

  const generateAvailabilityMessage = () => {
    const offers = watchServices[countryCode];

    if (!offers) {
      return (
        <p>We are sorry {filmTitle} is currently unavailable in your area.</p>
      );
    }

    if (offers.rent || offers.flatrate || offers.buy) {
      let message = "is currently available to ";
      const availableOptions = [];

      if (offers.rent) availableOptions.push("rent");
      if (offers.flatrate) availableOptions.push("stream");
      if (offers.buy) availableOptions.push("buy");

      if (availableOptions.length > 1) {
        const lastOption = availableOptions.pop(); // Remove the last option
        message += availableOptions.join(", ") + `, and ${lastOption}`;
      } else {
        message += availableOptions[0];
      }

      return `${filmTitle} ${message} in the United States. JustWatch makes it easy to find out where you can legally watch your favorite movies & TV shows online.`;
    }
  };

  return (
    <div className="just-watch-root">
      <div className="just-watch-header">
      </div>
      {generateAvailabilityMessage()}
      <div className="select-container">
        <Select
          defaultValue={getDefaultValue()}
          options={countryOptions}
          placeholder="Sort By"
          onChange={handleOptionChange}
        />
      </div>
      <div className="just-watch-stream-container">
        {watchServices[countryCode] && (
          <div className="stream-services-container">
            {watchServices[countryCode]?.flatrate && (
              <div className="stream-services-header">
                <h3>Stream</h3>
                <ul className="watch-service-list">
                  {watchServices[countryCode]?.flatrate.map(
                    (streamService: any) => (
                      <li key={streamService.logo_path}>
                        <img
                          src={BASE_LOGO_URL + streamService.logo_path}
                          alt="stream service"
                          className="streaming-logo"
                        />
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {watchServices[countryCode].rent && (
              <div className="stream-services-header">
                <h4>Rent</h4>
                <ul className="watch-service-list">
                  {watchServices[countryCode].rent.map((film: any) => (
                    <li>
                      <img
                        key={film.logo_path}
                        src={BASE_LOGO_URL + film.logo_path}
                        alt="stream service"
                        className="streaming-logo"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {watchServices[countryCode].buy && (
              <div className="stream-services-header">
                <h4>Buy</h4>
                <ul className="watch-service-list">
                  {watchServices[countryCode].buy.map((film: any) => (
                    <li key={film.logo_path}>
                      <img
                        src={BASE_LOGO_URL + film.logo_path}
                        alt="stream service"
                        className="streaming-logo"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default JustWatch;
