import React from "react";
import "./TotalResults.css";

interface TotalResultsProps {
  totalResults: number | undefined;
}

const TotalResults: React.FC<TotalResultsProps> = ({ totalResults }) => {
  const formattedTotalResults = totalResults?.toLocaleString();
  return (
    <>
      <span className="results-text">({formattedTotalResults})</span>
    </>
  );
};

export default TotalResults;
