import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import Loading from "../../Loading/index.tsx";
import "./Person.css";
import { convertDate } from "../../../util/helpers";
import NoResultBackup from "../NoResultBackup";
import SocialList from "../../SocialList/index.tsx";
import Poster from "../../Poster/index.tsx";
import TruncatedText from "../../TruncatedText/index.tsx";
import { BASE_API_URL } from "../../../util/constants.tsx";

const headers: HeadersInit = {
  Authorization: process.env.REACT_APP_MOVIE_KEY || "",
};

interface PersonData {
  id: number;
  profile_path: string;
  name: string;
  known_for_department: string;
  place_of_birth: string;
  birthday: string;
  biography: string;
}

const updateCreditInfo = (creditData: any) => {
  const info =
    creditData.cast.length > 0
      ? creditData.cast.sort((a: any, b: any) => a.popularity < b.popularity)
      : Object.values(
          creditData.crew.reduce((crewObj: { [id: number]: any }, obj: any) => {
            const id = obj.id;
            if (!crewObj[id]) {
              crewObj[id] = obj;
            }
            return crewObj;
          }, {})
        );
  return info;
};

const Person: React.FC = () => {
  const match = useMatch("/person/:personId");
  const { personId } = match?.params || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [person, setPerson] = useState<PersonData | null>(null);
  const [movieCredits, setMovieCredits] = useState<any>(null);

  useEffect(() => {
    const getPersonData = async () => {
      try {
        const [personResponse, creditsResponse] = await Promise.all([
          axios.get(`${BASE_API_URL}/person/${personId}`, {
            headers,
          }),
          axios.get(`${BASE_API_URL}/person/${personId}/movie_credits`, {
            headers,
          }),
        ]);

        const personData = personResponse.data;
        setPerson(personData);

        const creditData = creditsResponse.data;
        const creditInfo = updateCreditInfo(creditData);

        setMovieCredits(creditInfo);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    getPersonData();
  }, [personId]);

  const getAge = (Born: string) => {
    const currentDate = new Date();
    const birthdate = new Date(Born);
    let age = currentDate.getFullYear() - birthdate.getFullYear();

    if (
      currentDate.getMonth() < birthdate.getMonth() ||
      (currentDate.getMonth() === birthdate.getMonth() &&
        currentDate.getDate() < birthdate.getDate())
    ) {
      age--;
    }
    return age;
  };

  if (isLoading || !person || !movieCredits) {
    return <Loading />;
  }

  const {
    profile_path,
    name,
    known_for_department,
    place_of_birth,
    birthday,
    biography,
  } = person;

  const renderCreditSection = () => (
    <>
      <h2 className="person-bio-title header">Credit(s)</h2>
      <div className="person-credit-wrapper">
        {movieCredits.map((credit) => (
          <Poster
            key={credit.id}
            id={credit.id}
            movieTitle={credit.title}
            imageSrc={credit.poster_path}
          />
        ))}
      </div>
    </>
  );

  const renderBiography = () => (
    <>
      <h2 className="person-bio-title header">Biography</h2>
      {biography ? (
        <TruncatedText text={biography} maxLength={350} />
      ) : (
        <p>No biography at this time for {name}</p>
      )}
    </>
  );

  const renderDetail = (title: string, content: string) => (
    <div>
      <h4>{title}</h4>
      <p>{content}</p>
    </div>
  );

  return (
    <div className="person-container container">
      <h1 className="person-name">{name}</h1>
      <section className="person-small-details-wrapper">
        <div className="person-image-container">
          {profile_path ? (
            <img
              src={`https://image.tmdb.org/t/p/w185${profile_path}`}
              alt="headshot"
              className="person-image"
            />
          ) : (
            <div className="no-person-image">
              <NoResultBackup isUser={true} />
            </div>
          )}
          <SocialList
            url={`${BASE_API_URL}/person/${personId}/external_ids`}
            showHeader={false}
          />
        </div>

        <div className="person-wrapper">
          <h3 className="person-info-title header">Personal Info</h3>
          <section className="person-biography-details">
            {renderDetail("Known for", known_for_department)}
            {place_of_birth && renderDetail("From", place_of_birth)}
            {renderDetail("Born", convertDate(birthday))}
            {renderDetail("Age", getAge(birthday))}
            {renderDetail("Credit(s) ", movieCredits?.length)}
          </section>
          <div className="biography-container">{renderBiography()}</div>
        </div>
      </section>
      <section>
        <div className="person-credit-container">{renderCreditSection()}</div>
      </section>
    </div>
  );
};

export default Person;
