import React, { useCallback } from "react";
import "./Chip.css";
import { FilterOptions } from "../../models/movieData.model";

type ParamFunc = (event: React.MouseEvent<HTMLButtonElement>) => FilterOptions;

interface ChipProps {
  name: number | string;
  isSelected: boolean;
  chipCategory: string;
  onChipSelection: ParamFunc;
  label: string;
}

const Chip: React.FC<ChipProps> = ({
  name,
  isSelected,
  chipCategory,
  onChipSelection,
  label,
}) => {
  const handleChipSelection = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onChipSelection(event);
    },
    [onChipSelection]
  );

  return (
    <button
      key={crypto.randomUUID()}
      className={`button secondary chip ${isSelected && "chip-selected"}`}
      data-categoryvalue={name}
      name={chipCategory}
      onClick={handleChipSelection}
    >
      {label}
    </button>
  );
};

export default Chip;
