import React from "react";
import "./Pagination.css";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Page } from "../../../../models/movieData.model";


interface PaginationProps {
  paginationData: Page;
  onHandlePageBackward: () => void;
  onHandlePageForward: () => void;
  pageNumber: number;
}

const Pagination: React.FC<PaginationProps> = ({
  paginationData,
  onHandlePageBackward,
  onHandlePageForward,
  pageNumber,
}) => {


  return (
    <div className="pagination-container">
      <MdOutlineArrowBackIosNew
        onClick={onHandlePageBackward}
        className={`next-page ${paginationData?.page === 1 && "disabled"}`}
      />
      <h4>
        Page {paginationData?.page} of {paginationData.total_pages.toLocaleString()}
      </h4>
      <MdOutlineArrowForwardIos
        onClick={onHandlePageForward}
        className={`back-page ${
          paginationData.total_pages === pageNumber && "disabled"
        }`}
      />
    </div>
  );
};

export default Pagination;
