import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiMoviePlay } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { CgClose } from "react-icons/cg";

import "./NavBar.css";
import SearchInput from "../SearchInput";
import useHover from "../../hooks/useHover";

const NavBar: React.FC = () => {
  const [isSearchSelected, setSearchSelected] = useState<boolean>(false);
  const location = useLocation();

  const isSearchPage = location.pathname.includes("search-results");

  const { hoveredItem, handleMouseEnter, handleMouseLeave } = useHover();

  useEffect(() => {
    if (location.pathname.includes("search-results")) {
      setSearchSelected(false);
    }
  }, [location]);

  const handleCloseSearch = useCallback(() => {
    setSearchSelected(false);
  }, []);

  const handleOpenSearch = useCallback(() => {
    if (!isSearchPage) {
      setSearchSelected(true);
    }
  }, [isSearchPage]);

  const categories = [
    {
      title: "Movies",
      items: [
        { to: "/movie/popular", text: "Popular" },
        { to: "/movie/upcoming", text: "Upcoming" },
        { to: "/movie/top_rated", text: "Top Rated" },
        { to: "/movie/now_playing", text: "Now Playing" },
      ],
    },
    {
      title: "People",
      items: [{ to: "/people/popular", text: "Popular" }],
    },
    // {
    //   title: "TV",
    //   items: [
    //     {
    //       to: "/tv/popular",
    //       text: "Popular",

    //     },
    //     {
    //       to: "/tv/top_rated",
    //       text: "Top Rated",

    //     },
    //     {
    //       to: "/tv/on_the_air",
    //       text: "On The Air",

    //     },
    //     {
    //       to: "/tv/airing_today",
    //       text: "Airing Today",

    //     },
    //   ],
    // },
  ];

  return (
    <header className="nav-container">
      <nav className="nav-wrapper">
        <div className="nav-logo-container">
          <Link to={"/"} className="link">
            <h3 className="movie-spot-title">Film ST⭐️R DB</h3>
            <BiMoviePlay className="movie-spot-logo" fill="gold" />
          </Link>
        </div>
        {categories.map((category) => (
          <div
            key={category.title}
            className="category-wrapper"
            onMouseEnter={() => handleMouseEnter(category.title)}
            onMouseLeave={handleMouseLeave}
          >
            <h4 className="dropdown-toggle">{category.title}</h4>
            {hoveredItem === category.title && (
              <div className="dropdown-menu" onMouseLeave={handleMouseLeave}>
                <ul>
                  {category.items.map(({ to, text }) => (
                    <li key={to}>
                      <Link to={to} className="link" onBlur={handleMouseLeave}>
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
        {isSearchSelected ? (
          <>
            <CgClose className="nav-search" onClick={handleCloseSearch} />
            <div className="nav-search-container">
              <SearchInput />
            </div>
          </>
        ) : (
          <FaSearch className="nav-search" onClick={handleOpenSearch} />
        )}
      </nav>
    </header>
  );
};

export default NavBar;
